<!--
 * @Description  : 
 * @Author       : BigBigger
 * @Date         : 2022-03-29 13:39:10
 * @LastEditTime : 2022-05-16 09:57:38
 * @LastEditors  : BigBigger
-->
<template>
  <el-dialog title="修改个人信息" :visible.sync="visible" center>
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="真实姓名" prop="realName">
        <el-input v-model="form.realName"></el-input>
      </el-form-item>
      <el-form-item label="电话" prop="phone">
        <el-input v-model="form.phone"></el-input>
      </el-form-item>
      <el-form-item label="笔试总分" prop="totalScore">
        <el-input v-model="form.totalScore"></el-input>
      </el-form-item>
      <el-form-item label="笔试排名" prop="ranking">
        <el-input v-model="form.ranking"></el-input>
      </el-form-item>
      <el-form-item label-width="0" style="text-align: center;">
        <el-button type="primary" @click="submit()">确认</el-button>
        <el-button @click="visible = false">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { updatePersonalInfo } from '@/api/api';

export default {
  data() {
    return {
      visible: false,
      form: {},
      rules: {
        realName: [
          {required: true, message: '请输入真实姓名', trigger: 'blur'},
          {max: 5, message: '最多填写 5 个字符', trigger: 'blur' }
        ],
        phone: [
          {required: true, message: '请输入电话', trigger: 'blur'},
          {
            pattern: /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/,
            message: "请填写正确的手机号码", trigger: "blur"
          }
        ],
          totalScore: [
            { required: true, message: '请输入笔试总分', trigger: "blur" },
            {
            pattern: /(^[1-9]([0-9]+)?(\.[0-9]{1,3})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/,
            message: "请填写正确的总分", trigger: "blur"
          }
          ],
          ranking: [
            { required: true, message: '请输入笔试排名', trigger: "blur" },
            {
              pattern: /^[0-9][0-9]?$/,
              message: "请输入正确排名", trigger: "blur"
            }
          ],
      }
    };
  },
  methods: {
    show(value) {
      this.form = value;
      this.visible = true;
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (!valid) return;
        updatePersonalInfo(this.form).then(res => {
          if(+res.returnCode !== 10001) {
            return this.$message.error('修改失败');
          }
          this.$message.success('修改成功');
          this.visible = false;
          this.form = {};
          this.$emit('reload');
        }).catch(err => {
          this.$message.error('修改失败')
        })
      })
    }
  },
}
</script>